<template>
  <b-row>
    <b-col cols="12 px-lg-5 py-4">
      <b-row>
        <b-col cols="12" lg="9">
          <p class="info fs-30 fs-mobile-18 fw-bold mb-0">BIENVENID@</p>
          <p class="primary fs-35 fs-mobile-18 fw-bold mb-2">{{ nombreCliente }}</p>
          <b-form-select class="select-home fs-20 primary" v-model="creditoSelected">
            <b-form-select-option-group label="Número de obligación">
              <b-form-select-option
                :value="credito"
                :key="credito.numeroObligacion"
                v-for="credito in $store.getters.creditos"
              >
                {{ `Nro de Obligación ${credito.numeroObligacion} - ${getEstadoCredito(credito.estado)}` }}
              </b-form-select-option>
            </b-form-select-option-group>
          </b-form-select>
        </b-col>
        <b-col cols="12" class="py-3">
          <hr class="hr-home ml-0" />
        </b-col>
        <template v-if="creditoSelected !== null">
          <b-col cols="12" lg="6" class="fs-18 primary">
            <p class="fs-italic content-between-center-sm">
              <label class="label-info ml-0"><i class="las la-caret-right danger"></i>Saldo en Mora: </label>
              <label class="label-info fw-bold">
                <span :class="esSaldoMora ? 'danger' : 'success'">
                  {{ creditoSelected.saldoMora | currency }}
                  <i :class="esSaldoMora ? 'las la-exclamation-circle' : 'pl-2 pr-3'"></i>
                </span>
              </label>
            </p>
            <p class="fs-italic content-between-center-sm">
              <label class="label-info ml-0"><i class="las la-caret-right danger"></i>Próxima Cuota:</label>
              <label class="label-info fw-normal pr-4">{{ creditoSelected.proximaCuota | currency }}</label>
            </p>
            <p class="fw-bold content-between-center-sm">
              <label class="label-info ml-0"><i class="las la-caret-right danger"></i>Pago Mínimo:</label>
              <label class="label-info">
                <span v-if="!pagoNoDisponible">
                  {{ creditoSelected.valorPago | currency }}
                  <i id="popover-info" class="las la-question-circle info cursor-pointer"></i>
                </span>
                <span v-else class="pr-4">No Disponible</span>
              </label>
              <b-popover target="popover-info" placement="top" triggers="click" custom-class="bg-primary">
                <strong class="text-white">
                  Este Valor incluye el saldo en mora, seguros, valores que se hayan generado por alguna novedad en tu
                  crédito y el valor de tu próxima cuota a vencer.
                </strong>
              </b-popover>
            </p>
            <p class="fw-bold content-between-center-sm">
              <label class="label-info ml-0"><i class="las la-caret-right danger"></i>Saldo Total a la Fecha:</label>
              <label class="label-info pr-4">
                <span v-if="!pagoNoDisponible"> {{ creditoSelected.saldoTotalFecha | currency }}</span>
                <span v-else>No Disponible</span>
              </label>
            </p>
            <p class="fw-bold content-between-center-sm">
              <label class="label-info ml-0"><i class="las la-caret-right danger"></i>Estado:</label>
              <label class="label-info pr-4">
                <span v-if="esEstadoVigente" class="success">{{ creditoSelected.estado }}</span>
                <span v-else-if="esEstadoMora" class="danger">{{ creditoSelected.estado }}</span>
                <span v-else>{{ creditoSelected.estado }}</span>
              </label>
            </p>
            <p class="fw-bold content-between-center-sm mb-0">
              <label class="label-info ml-0"><i class="las la-caret-right danger"></i>Paguese Hasta:</label>
              <label class="label-info fw-bold pr-4" :class="esSaldoMora ? 'danger' : 'success'">
                {{ creditoSelected.fechaProximoPago }}
              </label>
            </p>
            <p v-if="!esSaldoMora" class="fs-italic fs-14 lh-1 text-right secondary pr-4">
              (AAAA/MM/DD)
            </p>
            <p class="fw-normal pt-3">
              Consulta las tasas de tu crédito:
              <i id="popover-tasas" class="las la-plus-circle info cursor-pointer"></i>
              <b-popover target="popover-tasas" placement="top" triggers="click" custom-class="bg-primary">
                <strong class="text-white py-1">
                  Tasa de Interés Mes Pactada: {{ `${creditoSelected.tasaInteresMesPactada}%` }}
                </strong>
                <strong class="text-white py-1">
                  Tasa Efectiva Anual: {{ `${creditoSelected.tasaEfectivaAnual}%` }}
                </strong>
              </b-popover>
            </p>
          </b-col>
          <b-col cols="12" lg="6">
            <b-row>
              <b-col cols="6">
                <p class="info text-coutas fs-20 fs-mobile-12 fw-bold content-center">
                  <label class="info fw-bold fs-40 mb-0 px-3">{{ creditoSelected.cuotasPendientes }}</label>
                  CUOTAS POR PAGAR
                </p>
              </b-col>
              <b-col cols="6">
                <p class="primary text-coutas fs-20 fs-mobile-12 fw-bold content-center">
                  <label class="info fw-bold fs-40 mb-0 px-3">{{ creditoSelected.cuotasPagadas }}</label> CUOTAS PAGADAS
                </p>
              </b-col>
              <b-col cols="10" lg="12" class="px-3 content-center">
                <div class="w-100 borde-progress">
                  <b-progress :value="calcularProgreso"></b-progress>
                </div>
                <i class="las la-flag-checkered fs-40 primary"></i>
              </b-col>
              <b-col cols="12">
                <hr class="hr-home ml-0" />
              </b-col>
              <b-col cols="12">
                <b-button
                  variant="danger"
                  @click="goToPagos"
                  v-if="esCreditoMora"
                  class="fs-italic fs-20 fs-mobile-16 mr-3 mb-3 fw-bold"
                >
                  {{ creditoSelected.diasEnMora }} días en mora
                </b-button>
                <label v-else class="btn bg-success fs-20 fs-mobile-16 mr-3 mb-3 cursor-default">
                  <span class="fs-italic fw-bold text-white">0 días en mora</span>
                </label>
                <b-button
                  variant="primary"
                  @click="goToPagos"
                  v-if="creditoSelected.valorPago > 0"
                  class="fs-italic fs-20 fs-mobile-16 mb-3 fw-bold"
                >
                  PAGAR
                </b-button>
                <p class="primary mb-2 fs-italic fs-20 fs-mobile-18 text-justify text-coutas">
                  {{ textoInformativo }}
                </p>
                <ul v-if="showTels" class="primary pl-4 fs-italic fs-20 fs-mobile-18 text-coutas">
                  <li :key="idx" v-for="(tel, idx) in telefonos">{{ tel }}</li>
                </ul>
              </b-col>
            </b-row>
          </b-col>
        </template>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import PagosService from "@/app/core/api/pagos";
import CarteraService from "@/app/core/api/cartera";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";

export default {
  mixins: [mixinsEvents],
  data() {
    return {
      dato: null,
      showTels: false,
      creditoSelected: null,
      pagoNoDisponible: false,
      nombreCliente: this.$store.getters.nombreCliente,
    };
  },
  computed: {
    calcularProgreso() {
      const { cuotasPagadas, cuotasPendientes } = this.creditoSelected;
      return (cuotasPagadas / (cuotasPagadas + cuotasPendientes)) * 100;
    },
    esCreditoCancelado() {
      return this.creditoSelected.estado.toUpperCase().startsWith("CANCELADO");
    },
    esCanceladoTotal() {
      return this.creditoSelected.estado.toUpperCase() === "CANCELADO";
    },
    esCreditoActivo() {
      return this.creditoSelected.edadCartera === 20;
    },
    verificarEdadesCartera() {
      const { edadCartera } = this.creditoSelected;
      return edadCartera === 4 || edadCartera === 5 || edadCartera === 7;
    },
    esCreditoMora() {
      return this.creditoSelected.diasEnMora > 0;
    },
    esEstadoMora() {
      return this.creditoSelected.estado === "MORA";
    },
    esEstadoVigente() {
      const { estado } = this.creditoSelected;
      return estado === "VIGENTE" || estado === "PAGADO" || estado === "APERTURADOS";
    },
    esSaldoMora() {
      return this.creditoSelected.saldoMora > 0;
    },
    textoInformativo() {
      if (this.esCreditoMora) return "Tu crédito está en mora. No te dejes alcanzar que ya estás llegando a la meta.";
      else if (this.esCanceladoTotal)
        return "Tu crédito se encuentra totalmente cancelado, puedes descargar tu Certificado de Paz y Salvo a través de la opción Certificados y Extractos.";
      else if (this.esCreditoActivo)
        return "¡Felicitaciones!, tu crédito se encuentra al día. Continúa realizando tus pagos a tiempo y abre las puertas de tu vida crediticia para que sigas progresando.";
      else if (!this.esCreditoCancelado && this.verificarEdadesCartera)
        return "Tu crédito se encuentra en una etapa de Mora Avanzada, para brindarte la información solicitada comunícate con nuestras Líneas de Servicio al Cliente ProgreSER.";
      return "";
    },
  },
  watch: {
    creditoSelected() {
      this.validarInfoCredito();
    },
  },
  beforeCreate() {
    if (this.$route.query.hashPago !== undefined) {
      const hashPago = this.$route.query.hashPago;

      PagosService.getEstadoTransaccion(hashPago)
        .then((response) => {
          this.$router.push("/home");

          const dataModal = { tipo: null, mensaje: null, titulo: "Proceso de Transacción" };

          if (response.data.estado === 0) {
            dataModal.tipo = "danger";
            dataModal.mensaje = "Transacción rechazada.";
          } else if (response.data.estado === 1) {
            dataModal.tipo = "success";
            dataModal.mensaje = "Transacción aprobada.";
          } else if (response.data.estado === 2) {
            dataModal.tipo = "info";
            dataModal.mensaje = "Transacción pendiente.";
          }

          this.$store.commit("openModalConfirms", dataModal);
        })
        .catch(() => {
          this.$router.push("/home");
        });
    }
  },
  created() {
    if (!this.$store.getters.isGetCreditos) this.getCreditos();
    else this.creditoSelected = this.$store.getters?.creditos[0];
  },
  methods: {
    getCreditos() {
      const { tipoDocumento, identificacion } = this.$store.getters;
      const body = { tipoDocumento: tipoDocumento, numeroDocumento: parseInt(identificacion), pantalla: "Home" };

      CarteraService.getCreditos(body)
        .then((response) => {
          if (this.$store.getters.msgActualizacionDatos)
            AlertsService.info("Perfil", this.$store.getters.msgActualizacionDatos);
          if (response.data.estado) {
            this.$store.commit("setCreditos", response.data.listaCreditos);
            this.creditoSelected = this.$store.getters?.creditos[0];
            this.$store.commit("setIsGetCreditos", true);
          } else AlertsService.error("Consulta de Creditos", response.data.mensaje);
        })
        .catch(() => {
          this.$store.dispatch("logout");
          AlertsService.error("Error", "No se han podido obtener los datos, inicia sesión nuevamente.");
        });
    },
    getEstadoCredito(estado) {
      return estado.toUpperCase().startsWith("CANCELADO") ? "CANCELADO" : "ACTIVO";
    },
    validarInfoCredito() {
      this.showTels = false;
      this.pagoNoDisponible = false;

      if (this.esCreditoCancelado) {
        this.creditoSelected.fechaProximoPago = "Pagado";
        this.creditoSelected.diasEnMora = 0;
        this.creditoSelected.valorPago = 0;
      }
      if (!this.esCreditoCancelado && this.verificarEdadesCartera) {
        this.creditoSelected.saldoTotalFecha = 0;
        this.creditoSelected.diasEnMora = 0;
        this.creditoSelected.valorPago = 0;
        this.pagoNoDisponible = true;
        this.showTels = true;
      }
    },
    goToPagos() {
      this.$store.commit("setCreditoSelected", this.creditoSelected);
      this.$router.push("/pagar-cuota");
    },
  },
};
</script>
