var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12 px-lg-5 py-4" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", lg: "9" } },
                [
                  _c(
                    "p",
                    { staticClass: "info fs-30 fs-mobile-18 fw-bold mb-0" },
                    [_vm._v("BIENVENID@")]
                  ),
                  _c(
                    "p",
                    { staticClass: "primary fs-35 fs-mobile-18 fw-bold mb-2" },
                    [_vm._v(_vm._s(_vm.nombreCliente))]
                  ),
                  _c(
                    "b-form-select",
                    {
                      staticClass: "select-home fs-20 primary",
                      model: {
                        value: _vm.creditoSelected,
                        callback: function ($$v) {
                          _vm.creditoSelected = $$v
                        },
                        expression: "creditoSelected",
                      },
                    },
                    [
                      _c(
                        "b-form-select-option-group",
                        { attrs: { label: "Número de obligación" } },
                        _vm._l(_vm.$store.getters.creditos, function (credito) {
                          return _c(
                            "b-form-select-option",
                            {
                              key: credito.numeroObligacion,
                              attrs: { value: credito },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "Nro de Obligación " +
                                      credito.numeroObligacion +
                                      " - " +
                                      _vm.getEstadoCredito(credito.estado)
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-col", { staticClass: "py-3", attrs: { cols: "12" } }, [
                _c("hr", { staticClass: "hr-home ml-0" }),
              ]),
              _vm.creditoSelected !== null
                ? [
                    _c(
                      "b-col",
                      {
                        staticClass: "fs-18 primary",
                        attrs: { cols: "12", lg: "6" },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "fs-italic content-between-center-sm",
                          },
                          [
                            _c("label", { staticClass: "label-info ml-0" }, [
                              _c("i", {
                                staticClass: "las la-caret-right danger",
                              }),
                              _vm._v("Saldo en Mora: "),
                            ]),
                            _c("label", { staticClass: "label-info fw-bold" }, [
                              _c(
                                "span",
                                {
                                  class: _vm.esSaldoMora ? "danger" : "success",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.creditoSelected.saldoMora
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("i", {
                                    class: _vm.esSaldoMora
                                      ? "las la-exclamation-circle"
                                      : "pl-2 pr-3",
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "fs-italic content-between-center-sm",
                          },
                          [
                            _c("label", { staticClass: "label-info ml-0" }, [
                              _c("i", {
                                staticClass: "las la-caret-right danger",
                              }),
                              _vm._v("Próxima Cuota:"),
                            ]),
                            _c(
                              "label",
                              { staticClass: "label-info fw-normal pr-4" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.creditoSelected.proximaCuota
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          { staticClass: "fw-bold content-between-center-sm" },
                          [
                            _c("label", { staticClass: "label-info ml-0" }, [
                              _c("i", {
                                staticClass: "las la-caret-right danger",
                              }),
                              _vm._v("Pago Mínimo:"),
                            ]),
                            _c("label", { staticClass: "label-info" }, [
                              !_vm.pagoNoDisponible
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.creditoSelected.valorPago
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "las la-question-circle info cursor-pointer",
                                      attrs: { id: "popover-info" },
                                    }),
                                  ])
                                : _c("span", { staticClass: "pr-4" }, [
                                    _vm._v("No Disponible"),
                                  ]),
                            ]),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: "popover-info",
                                  placement: "top",
                                  triggers: "click",
                                  "custom-class": "bg-primary",
                                },
                              },
                              [
                                _c("strong", { staticClass: "text-white" }, [
                                  _vm._v(
                                    " Este Valor incluye el saldo en mora, seguros, valores que se hayan generado por alguna novedad en tu crédito y el valor de tu próxima cuota a vencer. "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "fw-bold content-between-center-sm" },
                          [
                            _c("label", { staticClass: "label-info ml-0" }, [
                              _c("i", {
                                staticClass: "las la-caret-right danger",
                              }),
                              _vm._v("Saldo Total a la Fecha:"),
                            ]),
                            _c("label", { staticClass: "label-info pr-4" }, [
                              !_vm.pagoNoDisponible
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.creditoSelected.saldoTotalFecha
                                          )
                                        )
                                    ),
                                  ])
                                : _c("span", [_vm._v("No Disponible")]),
                            ]),
                          ]
                        ),
                        _c(
                          "p",
                          { staticClass: "fw-bold content-between-center-sm" },
                          [
                            _c("label", { staticClass: "label-info ml-0" }, [
                              _c("i", {
                                staticClass: "las la-caret-right danger",
                              }),
                              _vm._v("Estado:"),
                            ]),
                            _c("label", { staticClass: "label-info pr-4" }, [
                              _vm.esEstadoVigente
                                ? _c("span", { staticClass: "success" }, [
                                    _vm._v(_vm._s(_vm.creditoSelected.estado)),
                                  ])
                                : _vm.esEstadoMora
                                ? _c("span", { staticClass: "danger" }, [
                                    _vm._v(_vm._s(_vm.creditoSelected.estado)),
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(_vm.creditoSelected.estado)),
                                  ]),
                            ]),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "fw-bold content-between-center-sm mb-0",
                          },
                          [
                            _c("label", { staticClass: "label-info ml-0" }, [
                              _c("i", {
                                staticClass: "las la-caret-right danger",
                              }),
                              _vm._v("Paguese Hasta:"),
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "label-info fw-bold pr-4",
                                class: _vm.esSaldoMora ? "danger" : "success",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.creditoSelected.fechaProximoPago
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        !_vm.esSaldoMora
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "fs-italic fs-14 lh-1 text-right secondary pr-4",
                              },
                              [_vm._v(" (AAAA/MM/DD) ")]
                            )
                          : _vm._e(),
                        _c(
                          "p",
                          { staticClass: "fw-normal pt-3" },
                          [
                            _vm._v(" Consulta las tasas de tu crédito: "),
                            _c("i", {
                              staticClass:
                                "las la-plus-circle info cursor-pointer",
                              attrs: { id: "popover-tasas" },
                            }),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: "popover-tasas",
                                  placement: "top",
                                  triggers: "click",
                                  "custom-class": "bg-primary",
                                },
                              },
                              [
                                _c(
                                  "strong",
                                  { staticClass: "text-white py-1" },
                                  [
                                    _vm._v(
                                      " Tasa de Interés Mes Pactada: " +
                                        _vm._s(
                                          _vm.creditoSelected
                                            .tasaInteresMesPactada + "%"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "strong",
                                  { staticClass: "text-white py-1" },
                                  [
                                    _vm._v(
                                      " Tasa Efectiva Anual: " +
                                        _vm._s(
                                          _vm.creditoSelected
                                            .tasaEfectivaAnual + "%"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "12", lg: "6" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { cols: "6" } }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "info text-coutas fs-20 fs-mobile-12 fw-bold content-center",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "info fw-bold fs-40 mb-0 px-3",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.creditoSelected.cuotasPendientes
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" CUOTAS POR PAGAR "),
                                ]
                              ),
                            ]),
                            _c("b-col", { attrs: { cols: "6" } }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "primary text-coutas fs-20 fs-mobile-12 fw-bold content-center",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "info fw-bold fs-40 mb-0 px-3",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.creditoSelected.cuotasPagadas
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" CUOTAS PAGADAS "),
                                ]
                              ),
                            ]),
                            _c(
                              "b-col",
                              {
                                staticClass: "px-3 content-center",
                                attrs: { cols: "10", lg: "12" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w-100 borde-progress" },
                                  [
                                    _c("b-progress", {
                                      attrs: { value: _vm.calcularProgreso },
                                    }),
                                  ],
                                  1
                                ),
                                _c("i", {
                                  staticClass:
                                    "las la-flag-checkered fs-40 primary",
                                }),
                              ]
                            ),
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _c("hr", { staticClass: "hr-home ml-0" }),
                            ]),
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _vm.esCreditoMora
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "fs-italic fs-20 fs-mobile-16 mr-3 mb-3 fw-bold",
                                        attrs: { variant: "danger" },
                                        on: { click: _vm.goToPagos },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.creditoSelected.diasEnMora
                                            ) +
                                            " días en mora "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "label",
                                      {
                                        staticClass:
                                          "btn bg-success fs-20 fs-mobile-16 mr-3 mb-3 cursor-default",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "fs-italic fw-bold text-white",
                                          },
                                          [_vm._v("0 días en mora")]
                                        ),
                                      ]
                                    ),
                                _vm.creditoSelected.valorPago > 0
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "fs-italic fs-20 fs-mobile-16 mb-3 fw-bold",
                                        attrs: { variant: "primary" },
                                        on: { click: _vm.goToPagos },
                                      },
                                      [_vm._v(" PAGAR ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "primary mb-2 fs-italic fs-20 fs-mobile-18 text-justify text-coutas",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.textoInformativo) + " "
                                    ),
                                  ]
                                ),
                                _vm.showTels
                                  ? _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "primary pl-4 fs-italic fs-20 fs-mobile-18 text-coutas",
                                      },
                                      _vm._l(
                                        _vm.telefonos,
                                        function (tel, idx) {
                                          return _c("li", { key: idx }, [
                                            _vm._v(_vm._s(tel)),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }